import { jwtDecode } from 'jwt-decode';
import _ from 'lodash';

export class JwtAuth {
  constructor(params) {
    this.jwt = params['jwt'];
    this.targetType = params['target_type'];
    this.slug = params['application_identifier'];
  }

  canManage() {
    const decodedJwt = jwtDecode(this.jwt);
    const teamPrivileges = decodedJwt['team_privileges'];
    const applicationPermissions = decodedJwt['application_permissions'];
    const applicationPermission = _.get(applicationPermissions, `${this.slug}.${this.targetType}`, 'none');

    if (_.isEqual(teamPrivileges, ['admin'])) {
      return true;
    } else {
      return applicationPermission === 'manager';
    }
  }
}
