<template>
  <div
    v-for="(value, key) in messages"
    :key="key"
    class="modoui_banner_container"
  >
    <div
      class="alert alert-dismissible fade show modoui_banner"
      :class="value"
      role="alert"
      aria-expanded="true"
    >
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        @click="close(key)"
      >
        <span>
          ×
        </span>
      </button>
      <div>
        <span class="modoui_banner_message">
          {{ key }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../lib/event_bus';

const bannerClasses = {
  alarm: 'modoui_banner_alarm',
  success: 'modoui_banner_success',
};

export default {
  data: function () {
    return {
      messages: {},
    };
  },
  mounted() {
    EventBus.on('banner-event', options => {
      this.messages[options.message] = bannerClasses[options.messageType];
    });
  },
  methods: {
    close(key) {
      delete this.messages[key];
    }
  }
};
</script>
