<template>
  <div>
    <ModouiBreadcrumbs :breadcrumbs="breadcrumbs" />
    <ModouiContentHeading
      title="Manage Signing Tokens"
      sidebar-item="Manage Signing Tokens"
    >
      <div class="modoui_content_heading_actions">
        <a
          class="btn modoui_btn modoui_destructive"
          @click.prevent="destroy"
        >Revoke</a>
      </div>
    </ModouiContentHeading>

    <dl class="modoui_definition_list">
      <dt>
        Label
      </dt>
      <dd>
        {{ signingToken.label }}
      </dd>
      <dt>
        Kid
      </dt>
      <dd>
        {{ signingToken.id }}
      </dd>
      <dt>
        Targets
      </dt>
      <dd>
        {{ targets }}
      </dd>
      <dt>
        Role level
      </dt>
      <dd>
        {{ roleLevel }}
      </dd>
      <dt>
        Expires At
      </dt>
      <dd>
        {{ signingToken.formattedExpirationTime }}
      </dd>
      <div v-if="active === true">
        <dt>
          Json Web Token
        </dt>
        <dd class="text">
          <textarea
            v-model="jwt"
            :readonly="true"
            rows="10"
            style="width:500px;"
          />
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
import { EventBus } from '../../lib/event_bus';
import api from '~/api';
import { FlashMessages } from '~/lib/flash_messages';
import { PathHelpers } from '~/lib/path_helpers';

export default {
  inject: ['params'],
  data() {
    return {
      signingToken: {},
      jwt: '',
      targets: '',
      roleLevel: '',
      active: undefined,
      pathHelpers: new PathHelpers(this.params),
      flasher: new FlashMessages()
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          name: this.params['application_name'],
          href: this.pathHelpers.signingTokens(),
        },
        {
          name: this.signingToken.label,
          href: this.pathHelpers.signingToken({ signingTokenId: this.signingToken.id }),
        },
      ];
    },
  },
  mounted() {
    api.apiV1SigningTokens.get(this.params).then((response) => {
      const responseData = response.data;
      const warningMessage = responseData.meta.warning;

      this.signingToken = responseData;
      this.jwt = responseData.jsonWebToken;
      this.targets = responseData.targets.join(', ');
      this.roleLevel = responseData.roleLevel;
      if (warningMessage === undefined) {
        this.active = true;
      } else {
        this.active = false;
        EventBus.emit('banner-event', { message: warningMessage, messageType: 'alarm' });
      }
    });
  },
  methods: {
    destroy() {
      if(confirm('Are you sure you want to revoke this key? This action cannot be reversed.')){
        api.apiV1SigningTokens.destroy(this.params).then((response) => {
          this.flasher.notify(`Signing Token ${this.signingToken.label} deleted`);
          window.history.replaceState(null, '', this.pathHelpers.signingTokens());
          window.location.href = this.pathHelpers.signingTokens();
        }).catch(error => {
          EventBus.emit('banner-event', {
            message: 'There was an error deleting the Signing Token.',
            messageType: 'error'
          });
        });
      }
    }
  }
};
</script>
