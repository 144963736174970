import { createApp } from 'vue';
import AdminSidebar from '~/components/admin_sidebar.vue';
import ApplicationInstanceSidebar from '~/components/application_instance_sidebar.vue';
import ModouiBanner from '~/components/modoui/modoui_banner.vue';
import ModouiBreadcrumbs from '~/components/modoui/modoui_breadcrumbs.vue';
import ModouiChevron from '~/components/modoui/modoui_chevron.vue';
import ModouiContentHeading from '~/components/modoui/modoui_content_heading.vue';
import ModouiDatatable from '~/components/modoui/modoui_datatable.vue';
import ModouiEnabledDisabledIcon from '~/components/modoui/modoui_enabled_disabled_icon.vue';
import ModouiFormRow from '~/components/modoui/modoui_form_row.vue';
import ModouiSetSidebar from '~/components/modoui/modoui_set_sidebar.vue';
import ModouiSidebar from '~/components/modoui/modoui_sidebar.vue';
import ModouiSidebarInstanceSelector from '~/components/modoui/modoui_sidebar_instance_selector.vue';
import ServiceSigningKeyNew from '~/components/pages/service_signing_key_new.vue';
import ServiceSigningKeyShow from '~/components/pages/service_signing_key_show.vue';
import ServiceSigningKeysIndex from '~/components/pages/service_signing_keys_index.vue';
import SigningTokenNew from '~/components/pages/signing_token_new.vue';
import SigningTokenShow from '~/components/pages/signing_token_show.vue';
import SigningTokensIndex from '~/components/pages/signing_tokens_index.vue';
import TeamShow from '~/components/pages/team_show.vue';
import TeamsIndex from '~/components/pages/teams_index.vue';
import RootComponent from '~/components/root_component.vue';
import SearchBox from '~/components/search_box.vue';

const pageComponents = [
  {'name': 'ModouiBreadcrumbs', 'definition': ModouiBreadcrumbs },
  {'name': 'ModouiChevron', 'definition': ModouiChevron },
  {'name': 'ModouiContentHeading', 'definition': ModouiContentHeading },
  {'name': 'ModouiDatatable', 'definition': ModouiDatatable },
  {'name': 'ModouiEnabledDisabledIcon', 'definition': ModouiEnabledDisabledIcon },
  {'name': 'ModouiFormRow', 'definition': ModouiFormRow },
  {'name': 'ModouiSetSidebar', 'definition': ModouiSetSidebar },
  {'name': 'SearchBox', 'definition': SearchBox },
  {'name': 'ServiceSigningKeyNew', 'definition': ServiceSigningKeyNew },
  {'name': 'ServiceSigningKeyShow', 'definition': ServiceSigningKeyShow },
  {'name': 'ServiceSigningKeysIndex', 'definition': ServiceSigningKeysIndex },
  {'name': 'SigningTokenNew', 'definition': SigningTokenNew },
  {'name': 'SigningTokenShow', 'definition': SigningTokenShow },
  {'name': 'SigningTokensIndex', 'definition': SigningTokensIndex },
  {'name': 'TeamShow', 'definition': TeamShow },
  {'name': 'TeamsIndex', 'definition': TeamsIndex },
];

export function loadVue() {
  createBanner();
  createSidebars();
  createPageApp();
  enableBackButton();
}

function createBanner() {
  createCustomApp(ModouiBanner, 'vue_banner');
}

function createSidebars() {
  const components = [
    { 'name': 'ModouiSidebar', 'definition': ModouiSidebar },
    { 'name': 'ModouiSidebarInstanceSelector', 'definition': ModouiSidebarInstanceSelector },
  ];
  createCustomApp(ApplicationInstanceSidebar, 'vue_application_instance_sidebar', components);
  createCustomApp(AdminSidebar, 'vue_admin_sidebar', components);
}

function createPageApp() {
  createCustomApp(RootComponent, 'vue_app', pageComponents);
}

function createCustomApp(baseComponent, elementId, components = []) {
  const mountElement = document.getElementById(elementId);
  if (mountElement) {
    const app = createApp(baseComponent, { ...mountElement.dataset });
    components.forEach(function(component) {
      app.component(component['name'], component['definition']);
    });
    app.mount(mountElement);
  }
}

function enableBackButton() {
  window.addEventListener('popstate', (event) => {
    location.reload();
  });
}
