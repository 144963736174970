<template>
  <ModouiSidebar
    :sidebar-items="sidebarItems"
  />
</template>

<script>
export default {
  computed: {
    sidebarItems() {
      return [
        { name: 'Porter Administration', subItems: [
          { name: 'Manage Teams', href: '/admin/teams' },
          { name: 'Manage Service Signing Keys', href: '/admin/service_signing_keys' },
          { name: 'Sidekiq Pro', href: '/admin/sidekiq' },
        ]}
      ];
    }
  }
};
</script>
