<template>
  <table
    class="modoui_table dataTable hover row-border no-footer order-column"
    role="grid"
  >
    <thead>
      <tr role="row">
        <th
          v-for="column in columns"
          :key="column.headerName"
          :aria-label="column.headerName"
          :class="columnClasses(column)"
          @click="columnClicked(column)"
        >
          <span :class="{ table_head_sortable: column.sortable }">
            {{ column.headerName }}
          </span>
        </th>
      </tr>
    </thead>
    <tbody
      v-if="items.length > 0"
    >
      <tr
        v-for="item in items"
        :key="item[itemsKey]"
        :class="{ selected: (item === selectedItem) }"
        role="row"
        @click="$emit('rowClicked', item)"
      >
        <td
          v-for="column in columns"
          :key="column.attributeName"
          :class="{ modoui_align_right: column.type, modoui_datatable_actions: column.type }"
        >
          <component
            :is="column.type"
            v-if="column.type"
            :data="item[column.attributeName]"
          />
          <div v-else>
            {{ item[column.attributeName] }}
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td
          :colspan="columns.length"
          class="dataTables_empty"
          valign="top"
        >
          No matching records found
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true,
    },
    itemsKey: {
      type: String,
      default: 'uuid',
    },
    selectedItem: {
      type: Object,
      default: null,
    },
  },
  emits: ['columnSorted', 'rowClicked'],
  data() {
    return {
      sort: {}
    };
  },
  methods: {
    columnClasses(column) {
      return {
        'sorting': column.sortable,
        'sorting_asc': (this.sort.column === column && this.sort.order == 'asc'),
        'sorting_desc': (this.sort.column === column && this.sort.order == 'desc'),
      };
    },
    columnClicked(column) {
      if (!column.sortable) { return; };

      let order = 'asc';

      if (this.sort.column == column && this.sort.order == 'asc') {
        order = 'desc';
      }

      this.sort = { order: order, column: column };

      this.$emit('columnSorted', this.sort);
    }
  },
};
</script>
