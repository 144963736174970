<template>
  <a
    class="btn modoui_btn modoui_btn_small modoui_quiet modoui_borderless modoui_action"
    title="Details"
    :href="data.href"
    @click.stop="buttonClicked"
  >
    <i class="modoui_icon modoui_icon_drilldown modoui_icon_sm" />
  </a>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  methods: {
    buttonClicked() {
    },
  }
};
</script>
