import { EventBus } from '~/lib/event_bus';

export class FlashMessages {
  constructor(storage) {
    this.storage = storage || window.sessionStorage;
  }

  message() {
    return this.storage.getItem('flashMessage');
  }

  type() {
    return this.storage.getItem('flashType');
  }

  flash() {
    const message = this.message();

    if (message) {
      EventBus.emit('banner-event', {
        message: message,
        messageType: this.type()
      });
      this.clear();
    }
  }

  notify(message) {
    this.storage.setItem('flashMessage', message);
  }

  success(message) {
    this.storage.setItem('flashMessage', message);
    this.storage.setItem('flashType', 'success');
  }

  error(message) {
    this.storage.setItem('flashMessage', message);
    this.storage.setItem('flashType', 'alarm');
  }

  clear() {
    this.storage.removeItem('flashMessage');
    this.storage.removeItem('flashType');
  }

}
