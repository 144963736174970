<template>
  <ModouiSidebar
    :sidebar-items="sidebarItems"
    :instance-switcher="instanceSwitcher"
  />
</template>

<script>
import { JwtAuth } from '~/lib/jwt_auth';
import { PathHelpers } from '~/lib/path_helpers';

export default {
  props: {
    params: {
      type: String,
      required: true,
    },
    showInstanceSelector: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    auth() {
      return new JwtAuth(this.parsedParams);
    },
    parsedParams() {
      return JSON.parse(this.params);
    },
    pathHelpers() {
      return new PathHelpers({ 'application_identifier': this.slug, 'target_type': this.targetType });
    },
    sidebarItems() {
      const subItems = [
        { name: 'Manage Signing Tokens', href: this.pathHelpers.signingTokens() },
      ];
      const items = (this.auth.canManage()) ? [{ name: 'Overview', subItems: subItems }] : [];
      return items;
    },
    slug() {
      return this.parsedParams['application_identifier'];
    },
    targetType() {
      return this.parsedParams['target_type'];
    },
    instanceSwitcher() {
      if (this.showInstanceSelector === false) {
        return undefined;
      } else if (this.targetType === 'production') {
        return { production: undefined, test: `/applications/${this.slug}/test`};
      } else {
        return { production: `/applications/${this.slug}/production`, test: undefined };
      }
    }
  }
};
</script>
