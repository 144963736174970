<template>
  <a
    v-if="href"
    class="modoui_segmented_selector_item"
    aria-selected="false"
    :href="href"
  >
    {{ name }}
  </a>
  <span
    v-else
    class="modoui_segmented_selector_item modoui_selected"
    aria-selected="true"
  >
    {{ name }}
  </span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
      default: undefined,
    }
  }
};
</script>
