<template>
  <ModouiContentHeading title="Manage Service Signing Keys">
    <div class="modoui_content_heading_actions">
      <a
        role="button"
        class="btn modoui_btn modoui_constructive"
        href="/admin/service_signing_keys/new"
      >New Service Signing Key</a>
    </div>
  </ModouiContentHeading>
  <div class="dataTables_wrapper">
    <div class="dataTables_wrapper no-footer">
      <ModouiDatatable
        id="service_signing_key_index_table"
        :columns="columns"
        :items="serviceSigningKeys"
      />
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../lib/event_bus';
import api from '~/api';

export default {
  data() {
    return {
      columns: [
        { attributeName: 'kid', headerName: 'KID' },
        { attributeName: 'name', headerName: 'Name' },
        { attributeName: 'showChevron', headerName: '', type: 'ModouiChevron' }
      ],
      serviceSigningKeys: []
    };
  },
  mounted() {
    api.apiV1ServiceSigningKeys.list().then((response) => {
      response.data.forEach((serviceSigningKey) => {
        serviceSigningKey.showChevron = { href: '/admin/service_signing_keys/' + serviceSigningKey['kid'] };
        this.serviceSigningKeys.push(serviceSigningKey);
      });
    });
  }
};
</script>
