<template>
  <ModouiContentHeading
    title="Service Signing Key"
    sidebar-item="Manage Service Signing Keys"
    :required-fields="true"
  />
  <form class="modoui_form">
    <fieldset>
      <ModouiFormRow
        label="Name"
        field-name="name"
        :required="true"
        :errors="errors"
      >
        <input
          id="service-signing-key-name"
          v-model="name"
          name="service-signing-key-name"
          type="text"
        >
      </ModouiFormRow>

      <ModouiFormRow
        label="Public key"
        field-name="publicKey"
        :required="true"
        :errors="errors"
      >
        <textarea
          id="service-signing-key-public-key"
          v-model="publicKey"
          cols="5"
          rows="10"
          name="service-signing-key-public-key"
          type="text"
        />
      </ModouiFormRow>
    </fieldset>
    <div class="modoui_form_buttons">
      <button
        class="btn modoui_btn modoui_constructive"
        type="submit"
        @click.prevent="submit"
      >
        Create
      </button>
      <button
        class="btn modoui_btn modoui_destructive_quiet"
        @click.prevent="backToIndex"
      >
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import api from '~/api';
import { FlashMessages } from '~/lib/flash_messages';

export default {
  props: {
    backPath: {
      type: String,
      default: '../service_signing_keys',
    },
  },
  data() {
    return {
      errors: {},
      name: '',
      publicKey: '',
      flasher: new FlashMessages(),
    };
  },
  methods: {
    backToIndex() {
      window.location.href = this.backPath;
    },
    submit() {
      if (!this.formIsValid()) return;

      api.apiV1ServiceSigningKeys.create(this.createRequest())
        .then((response) => {
          this.flasher.success(`Service Signing Key ${this.name} created`);
          this.backToIndex();
        })
        .catch((error) => {
          error.response.data.errors.forEach((fieldError) => {
            this.errors[_.camelCase(fieldError.meta.attribute)] = fieldError.meta.message;
          });
        });
    },
    formIsValid() {
      this.validatePresence('name');
      this.validatePresence('publicKey');

      return !Object.keys(this.errors).length;
    },
    createRequest() {
      return {
        'data': {
          'data': {
            'type': 'service_signing_key',
            'attributes': {
              'name': this.name,
              'public_key': this.publicKey,
            }
          }
        }
      };
    },
    validatePresence(fieldName) {
      delete this.errors[fieldName];

      if (!this[fieldName]) this.errors[fieldName] = 'can\'t be blank';
    }
  },
};
</script>
