import _ from 'lodash';

export function filterSort(items, columns, sortInfo, searchQuery) {
  let filtered = _.clone(items);

  if (searchQuery) {
    searchQuery = searchQuery.toLowerCase();

    filtered = filtered.filter((item) => {
      for (const column of columns) {
        if (column.searchable && _.toString(item[column.attributeName]).toLowerCase().includes(searchQuery)) {
          return true;
        }
      }
    });
  }

  if (sortInfo) {
    filtered = _.orderBy(
      filtered,
      [sortInfo.column.sortableAttributeName || sortInfo.column.attributeName],
      [sortInfo.order]
    );
  }

  return filtered;
}
