<template>
  <ModouiSetSidebar :title="sidebarItem" />
  <div class="modoui_content_heading">
    <div class="modoui_content_heading_title_row">
      <div class="modoui_content_heading_title">
        <h1>{{ title }}</h1>
      </div>
      <slot />
    </div>
    <div
      v-if="requiredFields"
      class="modoui_secondary_text modoui_content_heading_description"
    >
      <span class="modoui_asterisk_required">*</span>
      Required fields
    </div>
  </div>
</template>

<script>
import { setPageTitle } from '../../lib/page_title';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    sidebarItem: {
      type: String,
      default: (props) => props.title,
      required: false,
    },
    requiredFields: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  mounted() {
    setPageTitle(this.title);
  }
};
</script>
