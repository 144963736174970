<template>
  <div v-if="canManage">
    <ModouiBreadcrumbs :breadcrumbs="breadcrumbs" />
    <ModouiContentHeading
      title="Manage Signing Tokens"
      sidebar-item="Manage Signing Tokens"
    >
      <div class="modoui_content_heading_actions">
        <a
          role="button"
          class="btn modoui_btn modoui_constructive"
          :href="newPath"
        >Create Signing Token</a>
      </div>
    </ModouiContentHeading>
    <div class="dataTables_wrapper">
      <div class="dataTables_wrapper no-footer">
        <SearchBox
          v-model="searchQuery"
          aria-controls="signing_tokens_index_table"
        />

        <ModouiDatatable
          id="signing_tokens_index_table"
          :columns="columns"
          :items="filteredSigningTokens"
          @column-sorted="columnSorted"
          @row-clicked="rowClicked"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '~/api';
import { filterSort } from '~/lib/filter_sort';
import { PathHelpers } from '~/lib/path_helpers';

export default {
  inject: ['params', 'auth'],
  data() {
    return {
      columns: [
        { attributeName: 'label', headerName: 'Token name', sortable: true, searchable: true },
        { attributeName: 'roleLevel', headerName: 'Role', sortable: true, searchable: true },
        {
          attributeName: 'formattedExpirationTime',
          headerName: 'Expires at',
          sortable: true,
          sortableAttributeName: 'expirationTime',
          searchable: true
        },
        { attributeName: 'showChevron', headerName: '', type: 'ModouiChevron' }
      ],
      signingTokens: [],
      pathHelpers: new PathHelpers(this.params),
      searchQuery: '',
      sort: undefined,
      canManage: this.auth.canManage(),
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          name: this.params['application_name'],
          href: this.pathHelpers.signingTokens(),
        },
      ];
    },
    filteredSigningTokens() {
      return filterSort(this.signingTokens, this.columns, this.sort, this.searchQuery);
    },
    newPath() {
      return this.pathHelpers.newSigningToken();
    }
  },
  mounted() {
    if (this.canManage) {
      const params = this.params;
      params['query'] = { target: params['target_param'] };

      api.apiV1SigningTokens.list(params).then((response) => {
        response.data.forEach((signingToken) => {
          const url = this.pathHelpers.signingToken({ signingTokenId: signingToken.id });
          signingToken.showChevron = { href: url };
          this.signingTokens.push(signingToken);
        });
      });
    }
  },
  methods: {
    columnSorted(sort) {
      this.sort = sort;
    },
    goTo(url) {
      window.history.pushState(null, null, url);
      window.location.href = url;
    },
    rowClicked(signingToken) {
      this.goTo(
        this.pathHelpers.signingToken({ signingTokenId: signingToken.id })
      );
    },
  }
};
</script>
