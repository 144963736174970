<template>
  <div />
</template>

<script>
import { EventBus } from '~/lib/event_bus';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  mounted() {
    EventBus.emit('sidebar-changed', this.title);
  }
};
</script>
