export class PathHelpers {
  constructor(params) {
    this.basePath = `/applications/${params['application_identifier']}/${params['target_type']}`;
  }

  newSigningToken() {
    return `${this.signingTokens()}/new`;
  }

  signingTokens() {
    return `${this.basePath}/signing_tokens`;
  }

  signingToken(params) {
    const signingTokenId = this.fetch(params, 'signingTokenId');

    return `${this.signingTokens()}/${signingTokenId}`;
  }

  fetch(params, key) {
    return params.hasOwnProperty(key) ? params[key] : (() => { throw new Error(`${key} not provided`); })();
  }
}
