<template>
  <ModouiContentHeading
    title="Service Signing Key"
    sidebar-item="Manage Service Signing Keys"
  >
    <div class="modoui_content_heading_actions">
      <a
        class="btn modoui_btn modoui_destructive"
        @click.prevent="destroy"
      >Revoke</a>
    </div>
  </ModouiContentHeading>

  <dl class="modoui_definition_list">
    <dt id="service_signing_keys_kid">
      Kid
    </dt>
    <dd>
      {{ serviceSigningKey.kid }}
    </dd>
    <dt id="service_signing_keys_name">
      Name
    </dt>
    <dd>
      {{ serviceSigningKey.name }}
    </dd>
    <dt id="service_signing_keys_public_key">
      Public Key
    </dt>
    <dd>
      <code class="modoui_code_block">
        {{ serviceSigningKey.publicKey }}
      </code>
    </dd>
  </dl>
</template>

<script>
import { EventBus } from '../../lib/event_bus';
import api from '~/api';
import { FlashMessages } from '~/lib/flash_messages';

export default {
  inject: ['params'],
  data() {
    return {
      serviceSigningKey: {
        kid: '',
        name: '',
        publicKey: '',
      },
      flasher: new FlashMessages()
    };
  },
  mounted() {
    api.apiV1ServiceSigningKeys.get(this.params).then((response) => {
      this.serviceSigningKey = response.data;
    });
  },
  methods: {
    destroy() {
      if(confirm('Are you sure you want to revoke this key? This action cannot be reversed.')){
        api.apiV1ServiceSigningKeys.destroy(this.params).then((response) => {
          this.flasher.notify(`Service Signing Key ${this.serviceSigningKey.name} deleted`);
          window.location.href = '/admin/service_signing_keys';
        }).catch(error => {
          EventBus.emit('banner-event', {
            message: 'There was an error deleting the Service Signing Key.',
            messageType: 'error'
          });
        });
      }
    }
  }
};
</script>
