<template>
  <div
    class="dataTables_filter"
  >
    <label>
      <input
        :value="modelValue"
        type="search"
        class=""
        placeholder="Search"
        :aria-controls="ariaControls"
        title="Search"
        @input="$emit('update:modelValue', $event.target.value.toString().toLowerCase())"
      >
    </label>
  </div>
</template>

<script>
export default {
  props: {
    ariaControls: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
};
</script>
