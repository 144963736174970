<template>
  <div>
    <component :is="vueComponent" />
  </div>
</template>

<script>
import { Config } from '@js-from-routes/client';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { JwtAuth } from '~/lib/jwt_auth';
import { notFoundInterceptor } from '~/lib/not_found_interceptor';

export default {
  provide() {
    return {
      params: this.parsedParams,
      auth: this.auth
    };
  },
  props: {
    params: {
      type: String,
      required: true,
    },
  },
  computed: {
    auth() {
      return new JwtAuth(this.parsedParams);
    },
    jwt() {
      return this.parsedParams['jwt'];
    },
    vueComponent() {
      return this.parsedParams['vue_component'];
    },
    parsedParams() {
      return JSON.parse(this.params);
    },
  },
  beforeMount() {
    Config.deserializeData = (data) => {
      return deserialize(data, { transformKeys: 'camelCase' });
    };
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.jwt;

    axios.interceptors.response.use(undefined, notFoundInterceptor);
  },
};
</script>
