// JsFromRoutes CacheKey 8af126cca5bf04fd58c02d20812ac2ea
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/service_signing_keys'),
  create: definePathHelper('post', '/api/service_signing_keys'),
  get: definePathHelper('get', '/api/service_signing_keys/:id'),
  destroy: definePathHelper('delete', '/api/service_signing_keys/:id'),
}
