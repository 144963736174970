<template>
  <div>
    <ModouiBreadcrumbs :breadcrumbs="breadcrumbs" />
    <ModouiContentHeading
      title="Create Signing Token"
      sidebar-item="Manage Signing Tokens"
      :required-fields="true"
    />
    <form class="modoui_form">
      <fieldset>
        <ModouiFormRow
          label="Label"
          field-name="label"
          :required="true"
          :errors="errors"
        >
          <input
            id="signing-token-label"
            v-model="label"
            name="signing-token-label"
            type="text"
          >
        </ModouiFormRow>
        <ModouiFormRow
          label="Days active"
          field-name="daysActive"
          :required="true"
          :errors="errors"
        >
          <input
            id="signing-token-days-active"
            v-model="daysActive"
            name="signing-token-days-active"
            type="text"
          >
        </ModouiFormRow>
        <div
          class="modoui_form_row modoui_form_radio_list modoui_form_required"
        >
          <label>Role level</label>
          <div class="modoui_form_value">
            <ul>
              <li>
                <label
                  for="signing-token-role-level-manager"
                  class="modoui_form_radio_list_label"
                >
                  <input
                    id="signing-token-role-level-manager"
                    v-model="roleLevel"
                    name="signing-token-role-level-manager"
                    value="manager"
                    type="radio"
                  >
                  <span>Manager</span>
                </label>
              </li>
              <li>
                <label
                  for="signing-token-role-level-member"
                  class="modoui_form_radio_list_label"
                >
                  <input
                    id="signing-token-role-level-member"
                    v-model="roleLevel"
                    name="signing-token-role-level-member"
                    value="member"
                    type="radio"
                  >
                  <span>Member</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <ModouiFormRow
          label="All targets"
          field-name="allTargets"
          :required="true"
        >
          <input
            id="signing-token-all-targets"
            v-model="allTargets"
            name="signing-token-all-targets"
            type="checkbox"
          >
        </ModouiFormRow>
      </fieldset>
      <div class="modoui_form_buttons">
        <button
          class="btn modoui_btn modoui_constructive"
          type="submit"
          @click.prevent="submit"
        >
          Create
        </button>
        <button
          class="btn modoui_btn modoui_destructive_quiet"
          @click.prevent="backToIndex"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import _ from 'lodash';
import api from '~/api';
import { FlashMessages } from '~/lib/flash_messages';
import { PathHelpers } from '~/lib/path_helpers';

export default {
  inject: ['params'],
  data() {
    return {
      errors: {},
      label: '',
      daysActive: '365',
      roleLevel: 'manager',
      allTargets: true,
      pathHelpers: new PathHelpers(this.params),
      flasher: new FlashMessages(),
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          name: this.params['application_name'],
          href: this.pathHelpers.signingTokens(),
        },
        {
          name: 'Create Signing Token',
          href: this.pathHelpers.newSigningToken(),
        },
      ];
    },
  },
  methods: {
    backToIndex() {
      window.location.href = this.pathHelpers.signingTokens();
    },
    submit() {
      if (!this.formIsValid()) return;

      api.apiV1SigningTokens.create(this.createRequest())
        .then((response) => {
          const signingTokenId = response.data.id;
          const url = this.pathHelpers.signingToken({ signingTokenId: signingTokenId });
          this.goTo(url);
        })
        .catch((error) => {
          error.response.data.errors.forEach((fieldError) => {
            this.errors[_.camelCase(fieldError.meta.attribute)] = fieldError.meta.message;
          });
        });
    },
    formIsValid() {
      this.validatePresence('label');
      this.validatePresence('daysActive');

      return !Object.keys(this.errors).length;
    },
    createRequest() {
      return {
        params: Object.assign({}, this.params),
        'data': {
          'data': {
            'type': 'signing_token',
            'attributes': {
              'label': this.label,
              'days_active': parseInt(this.daysActive),
              'role_level': this.roleLevel,
              'targets': this.allTargets ? ['prod', 'test'] : [this.params['target_param']]
            }
          }
        }
      };
    },
    validatePresence(fieldName) {
      delete this.errors[fieldName];

      if (!this[fieldName]) this.errors[fieldName] = 'can\'t be blank';
    },
    goTo(url) {
      this.flasher.success(`Signing Token ${this.label} created`);
      window.history.pushState(null, null, url);
      window.location.href = url;
    },
  },
};
</script>
