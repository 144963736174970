<template>
  <div class="modoui_breadcrumbs">
    <a
      v-for="breadcrumb in breadcrumbs"
      :key="breadcrumb.name"
      class="modoui_drilldown_link_small modoui_breadcrumb_item"
      :href="breadcrumb.href"
    >{{ breadcrumb.name }}</a>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    }
  }
};
</script>
