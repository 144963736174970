<template>
  <template v-if="data">
    <EnabledComponent title="enabled" />
  </template>
  <template v-else>
    <DisabledComponent title="disabled" />
  </template>
</template>

<script>
import DisabledComponent from '~/images/modoui/disabled.svg';
import EnabledComponent from '~/images/modoui/enabled.svg';

export default {
  components: {
    EnabledComponent,
    DisabledComponent
  },
  props: {
    data: {
      type: Boolean,
      required: true,
    }
  }

};
</script>
