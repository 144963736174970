<template>
  <div
    class="modoui_form_row"
    :class="{ modoui_form_error: errorMessage, modoui_form_required: required}"
  >
    <label :for="domId">{{ label }}</label>
    <div class="modoui_form_value">
      <slot />
      <span
        v-if="errorMessage"
        class="modoui_form_error_message"
      >
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      default() { return {}; },
    },
    fieldName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    errorMessage() {
      return this.errors[this.fieldName];
    },
    domId() {
      return this.$slots.default()[0].props['id'];
    }
  }
};
</script>
