// JsFromRoutes CacheKey 999b9b291533fb3339acd4ee64eff4b5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/applications/:application_identifier/signing_tokens'),
  create: definePathHelper('post', '/api/applications/:application_identifier/signing_tokens'),
  get: definePathHelper('get', '/api/applications/:application_identifier/signing_tokens/:uuid'),
  destroy: definePathHelper('delete', '/api/applications/:application_identifier/signing_tokens/:uuid'),
}
