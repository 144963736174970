<template>
  <div class="modoui_navbar">
    <div
      id="modoui_sidebar_component"
      class="modoui_navbar_collapse collapse show"
    >
      <section
        v-if="instanceSwitcher"
        id="application_instance_switcher_section"
        class="modoui_leftnav_toggle"
      >
        <div class="modoui_segmented_selector modoui_fill_width">
          <ModouiSidebarInstanceSelector
            name="Production"
            :href="instanceSwitcher.production"
          />
          <ModouiSidebarInstanceSelector
            name="Test"
            :href="instanceSwitcher.test"
          />
        </div>
      </section>
      <section
        v-for="item in sidebarItems"
        :key="item.name"
      >
        <div class="modoui_collapse_link modoui_collapse_link_large">
          <h1>{{ item.name }}</h1>
        </div>
        <div>
          <ul>
            <li
              v-for="subItem in item.subItems"
              :key="subItem.name"
              :class="{modoui_selected: selected(subItem)}"
            >
              <a :href="subItem.href"> {{ subItem.name }} </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import { EventBus } from '../../lib/event_bus';

export default {
  props: {
    sidebarItems: {
      type: Array,
      required: true,
    },
    instanceSwitcher: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      currentTitle: null
    };
  },
  created() {
    EventBus.on('sidebar-changed', title => {
      this.currentTitle = title;
    });
  },
  methods: {
    selected(item) {
      return item.name === this.currentTitle;
    }
  }
};
</script>
